<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="npre"
    show-expand
    sort-by="npre"
    sort-desc
    class="elevation-1"
    @current-items="currentItems"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Budgets List</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <!-- Filtros -->
        <v-dialog v-if="search_tool" v-model="dialogFilters" max-width="400px">
          <template v-slot:activator="{ on }">
            <!--<v-btn
              class="ml-4"
              color="blue-grey"
              fab
              small
              dark
              v-on="on"
              @click="$emit = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>-->
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Advanced Search</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      :items="filters"
                      v-model="filters.text"
                      label="Filters"
                      :clearable="true"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-menu
                      ref="rangeCalendar"
                      v-model="rangeCalendar"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Dates"
                          hint="Enter a date range range"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="rangeDates"
                        no-title
                        range
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="rangeDates = []"
                          >Clear</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rangeCalendar.save(rangeDates)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogFilters = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="filterItems(filters.text)"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Fin Filtros -->

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
              >New Budget</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.npre"
                      label="Npre"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.work_order"
                      label="Work Order"
                      prepend-icon="folder"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      v-model="popDatepick1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.date"
                          label="Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.date"
                        @input="popDatepick1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.user"
                      label="User"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.subid"
                      label="Subid"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.invoice"
                      label="Invoice"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="save(items)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item, 'dialog')"
        v-b-tooltip.hover
        title="Edit"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item,items)"
        v-b-tooltip.hover
        title="Delete"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers }">
      <td :colspan="headers.length">
        <div class="row justify-center">
          <div class="col-md-11">
            <v-container fluid>
              <v-data-table
                :headers="headers_extra"
                :items="items_extra"
                :search="search"
                :hide-default-footer="true"
                item-key="n"
                sort-by="n"
                class="elevation-1 transparent-table"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Budget Data</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog2" ref="dialog" max-width="500px">
                      <template v-slot:activator="{ on }">
                        <v-btn color="gray" class="mb-2" v-on="on"
                          >New Data</v-btn
                        >
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle2 }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.n"
                                  label="n"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.description"
                                  label="description"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.responsable"
                                  label="responsable"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.amount"
                                  label="amount"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.unit"
                                  label="unit"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.discount"
                                  label="discount"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.total"
                                  label="total"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="close"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="save(items_extra)"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'dialog2')"
                    v-b-tooltip.hover
                    title="Edit"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item, items_extra)"
                    v-b-tooltip.hover
                    title="Delete"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-container>
          </div>
        </div>
      </td>
    </template>
    <template slot="footer">
      <table>
        <tr
          class="border border-right-0 border-left-0 border-bottom-0 text-center"
        >
          <td colspan="">
            <v-chip color="lighten-1 mr-4">
              Items: &nbsp;
              <strong class="ml-2">{{ itemsFiltered }}</strong>
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    search: "",
    expanded: [],
    singleExpand: false,
    popDatepick1: false,
    editedIndex: -1,
    editedItem: {
      npre: 0,
      work_order: 0,
      date: "",
      user: "",
      subid: "",
      invoice: "",
      n: "",
      description: "",
      responsable: "",
      amount: "",
      unit: "",
      discount: "",
      total: ""
    },
    defaultItem: {
      npre: 0,
      work_order: 0,
      date: "",
      user: "",
      subid: "",
      invoice: "",
      n: 0,
      description: "",
      responsable: "",
      amount: "",
      unit: "",
      discount: "",
      total: ""
    },
    switch1: true,
    switch2: false,
    switch3: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Work Order", value: 1 },
      { text: "N Pre", value: 0 },
      { text: "User", value: 3 },
      { text: "Subid", value: 4 },
      { text: "Invoice", value: 5 }
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Budget" : "Edit Budget";
    },
    formTitle2() {
      return this.editedIndex === -1 ? "New Data" : "Edit Data";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    }
  },

  props: ["headers", "headers_extra", "items", "items_extra", "search_tool"],

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {

    editItem(item, dialog) {
      /* Listado de Dialogs */
      if(dialog == 'dialog'){
        this.dialog = true;
      }else if(dialog == 'dialog2'){
        this.dialog2 = true;
      }
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      this.dialog2 = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save(item) {
      if (this.editedIndex > -1) {
        Object.assign(item[this.editedIndex], this.editedItem);
      } else {
        item.push(this.editedItem);
      }
      this.close();
    },

    reset(){
      this.$emit('initialize');
    },

    /* Filtros & Loading */
    currentItems(item) {
      this.loading = true;
      setTimeout(() => {
        this.totalTime = item.reduce((a, b) => a + (b["time"] || 0), 0);
        /* Filtros */
        this.itemsFiltered = item.length;
        this.loading = false;
      }, 300);
    },
    filterItems(itemFiltered) {
      if (itemFiltered) {
        if (itemFiltered == 99) {
          this.headers.forEach(header => (header.filterable = true));
        } else {
          this.headers.forEach(header => (header.filterable = false));
          this.headers[itemFiltered].filterable = true;
        }
      } else {
        this.headers.forEach(header => (header.filterable = true));
      }
      this.dialogFilters = false;
      return this.headers;
    },
    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    }
  },
  components: {},
  mounted() {
    setTimeout(() => {
      this.$refs.search.focus();
    }, 500);
  }
};
</script>
