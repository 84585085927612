<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <BudgetsList
            :headers="headersBudgets"
            :headers_extra="headersBudgetsExtras"
            :items="itemsBudgets"
            :items_extra="itemsBudgetsExtras"
            @initialize="initialize"
            :search_tool="true"
          ></BudgetsList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import BudgetsList from "@/views/pages/budgets/BudgetsList.vue";

export default {
  components: {
    BudgetsList,
  },

  data: () => ({
    headersBudgets: [
      { text: "N Pre", sortable: true, value: "npre", filterable: true },
      {
        text: "Work Order",
        value: "work_order",
        sortable: false,
        filterable: true,
      },
      { text: "Date", value: "date", sortable: false },
      { text: "User", value: "user", sortable: false, filterable: true },
      { text: "Subid", value: "subid", sortable: false, filterable: true },
      { text: "Invoice", value: "invoice", sortable: false, filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersBudgetsExtras: [
      { text: "N", value: "n", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Responsable", value: "responsable", sortable: false },
      { text: "Amount", value: "amount", sortable: false },
      { text: "Unit", value: "unit", sortable: false },
      { text: "Discount", value: "discount", sortable: false },
      { text: "Total", value: "total", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsBudgets: [],
    itemsBudgetsExtras: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      (this.itemsBudgets = [
        {
          npre: "3943",
          work_order: 12159,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "Buenos Aires",
        },
        {
          npre: "3944",
          work_order: 12237,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "Buenos Aires",
        },
        {
          npre: "3945",
          work_order: 12262,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "Colonia",
        },
        {
          npre: "3946",
          work_order: 12305,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "Dock Sud",
        },
        {
          npre: "3947",
          work_order: 12356,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "Buenos Aires",
        },
        {
          npre: "3948",
          work_order: 12375,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "Campana",
        },
        {
          npre: "3950",
          work_order: 12392,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "San Nicolás",
        },
        {
          npre: "3951",
          work_order: 12408,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "Zarate",
        },
        {
          npre: "3952",
          work_order: 12452,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "Rosario",
        },
        {
          npre: "3953",
          work_order: 12518,
          date: "2019-10-23",
          user: "Eric Van Beers",
          subid: "NCC JUBAIL",
          invoice: "Rio Luján",
        },
      ]),
        (this.itemsBudgetsExtras = [
          {
            n: 1,
            description: "Buquebus",
            responsable: "Espona Cesar",
            amount: "1",
            unit: "$150,00",
            discount: "0%",
            total: "$150,00",
          },
          {
            n: 2,
            description: "Buquebus",
            responsable: "Espona Cesar",
            amount: "1",
            unit: "$150,00",
            discount: "0%",
            total: "$150,00",
          },
          {
            n: 3,
            description: "Buquebus",
            responsable: "Espona Cesar",
            amount: "1",
            unit: "$150,00",
            discount: "0%",
            total: "$150,00",
          },
        ]);
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Budgets" }]);
  },
};
</script>
